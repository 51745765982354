const Pickup = () => {
  return (
    <>
      <div className="pick_up section">
        <div className="pick_up heading">
          <h2>Free Pick Up And Drop Off Service - We Serve All Suburbs</h2>
        </div>
        <div className="pick_up content">
          <div className="pick_up info">
            <p>
              Rug Cleaning Melbourne - Cleaning the rugs using the DIY methods
              only cleans the surface, leaving behind a mix of soil and chemical
              residue. The best way to clean a rug is to get it cleaned
              professionally from the rug cleaning experts in order to achieve
              the best results. Whether your rug is a treasured Persian or a
              modern machine-made creation, the care we take when cleaning and
              sanitizing it, remains the same.
            </p>
            <p>
              Whenever we take on a new rug cleaning project, we comply with the
              guidelines. We complete a detailed analysis of the rug before we
              begin our job to decide the ideal approach to clean it. Generally,
              pre-vacuuming lifts loose dirt and it allows us to attack the
              deeper-rooted grime. In order to ensure that they are fully
              removed during the washing, localized stains and spots require
              special pre-treatment.
            </p>
          </div>
          <div className="pick_up img">
            <img
              loading="lazy"
              src={require("../../../Assets/Images/Dilivery/1.webp")}
              alt=""
            />
          </div>
        </div>
      </div>
      {/* <div className="lease-banner">
        <div className="wrapper">
          <div className="img">
            <img
              src={require("../../../Assets/Images/Icons/badge.png")}
              alt=""
            />
          </div>
          <div className="content">
            <h3>MOVING OUT?</h3>
            <h2>WORRIED ABOUT YOUR BOND?</h2>
            <p>
              - Our end of lease sofa cleaning gives 100% bond back guarantee.
            </p>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Pickup;

import { Helmet } from "react-helmet";
import React, { Suspense } from "react";
const Banner = React.lazy(() => import("../../common/Banner/Banner"));
const Footer = React.lazy(() => import("../../common/Footer/Footer"));
const Navbar = React.lazy(() => import("../../common/navbar/navbar"));
const ContactComp = React.lazy(() =>
  import("../../common/Contact/ContactComponent")
);
const NavMain = React.lazy(() => import("../../common/navbar/NavMain"));
const GallerySubComp = React.lazy(() => import("./GallerySubComp"));

const RugGallery = () => {
  document.title = "Rug Cleaning Gallery Album - Pure N Bright Cleaning";
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Rug cleaning gallery album - Checkout Our Recent Before / After Carpet Cleaning Work."
        />
        <meta name="keywords" content="" />
      </Helmet>
      <Suspense fallback={<p>loading..</p>}>
        <Navbar />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <NavMain />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <Banner
          img={require("../../../Assets/Images/Banner/gallery-sub-banner.jpg")}
          sublink="Gallery"
          sublink2="Rug Cleaning Gallery"
        />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <GallerySubComp
          title="Rug"
          images={[
            {
              src: require("../../../Assets/Images/Gallery/Rug/1.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/2.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/3.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/4.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/5.webp"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/6.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/7.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/8.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/9.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/10.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/11.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/12.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/13.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/14.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/15.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/16.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/17.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/18.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/19.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/20.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/21.jpeg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/22.jpeg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/23.jpeg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/24.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/25.jpeg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/26.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/27.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/28.png"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/29.png"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Rug/30.jpeg"),
            },
          ]}
        />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <ContactComp />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <Footer />
      </Suspense>
    </>
  );
};

export default RugGallery;

import { Helmet } from "react-helmet";
import "./Gallery.css";
import React, { Suspense } from "react";
const Banner = React.lazy(() => import("../../common/Banner/Banner"));
const Footer = React.lazy(() => import("../../common/Footer/Footer"));
const Navbar = React.lazy(() => import("../../common/navbar/navbar"));
const ContactComp = React.lazy(() =>
  import("../../common/Contact/ContactComponent")
);
const NavMain = React.lazy(() => import("../../common/navbar/NavMain"));
const GalleryComp = React.lazy(() => import("./GalleryComp"));

const Gallery = () => {
  document.title =
    "Checkout Our Recent Before After Cleaning Work in Melbourne - Pure N Bright Cleaning";
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Checkout our recent before after cleaning work in Melbourne for Carpet cleaning, Mattress cleaning, Tile and Grout cleaning, Upholstery cleaning, Rug cleaning, Window cleaning and Leather cleaning."
        />
        <meta name="keywords" content="" />
      </Helmet>
      <Suspense fallback={<p>loading..</p>}>
        <Navbar />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <NavMain />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <Banner
          img={require("../../../Assets/Images/Banner/gallery-banner.jpg")}
          sublink="Gallery"
          slogan="Checkout Our Albums"
        />
      </Suspense>
      <div className="gallery">
        <div className="gallery-heading">
          <h1>Before/After Cleaning Gallery</h1>
        </div>
        <div className="gallery_wrapper">
          <Suspense fallback={<p>loading..</p>}>
            <GalleryComp
              title="Carpet Cleaning"
              img={require("../../../Assets/Images/Gallery/carpet-cleaning-album.webp")}
              link="/gallery/carpet-cleaning-gallery"
            />

            <GalleryComp
              title="RUG Cleaning"
              img={require("../../../Assets/Images/Gallery/rug-cleaning-album.webp")}
              link="/gallery/rug-cleaning-gallery"
            />
            <GalleryComp
              title="UPHOLSTERY Cleaning"
              img={require("../../../Assets/Images/Gallery/upholstery-cleaning-album.webp")}
              link="/gallery/upholstery-cleaning-gallery"
            />
            <GalleryComp
              title="leather Cleaning"
              img={require("../../../Assets/Images/Gallery/Leather-cleaning-album.webp")}
              link="/gallery/leather-cleaning-gallery"
            />
            <GalleryComp
              title="TILE AND GROUT Cleaning"
              img={require("../../../Assets/Images/Gallery/tile-cleaning-album.webp")}
              link="/gallery/tile-grout-cleaning-gallery"
            />
            <GalleryComp
              title="Mattress Cleaning"
              img={require("../../../Assets/Images/Gallery/tile-cleaning-album.webp")}
              link="/gallery/mattress-cleaning-gallery"
            />
          </Suspense>
        </div>
      </div>
      <Suspense fallback={<p>loading..</p>}>
        <ContactComp />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <Footer />
      </Suspense>
    </>
  );
};

export default Gallery;

import Home from "./Components/pages/Home/Home";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Contact from "./Components/pages/Contact/Contact";
import FAQ from "./Components/pages/FAQ/FAQ";
import Blog from "./Components/pages/Blog/Blog";
import Terms from "./Components/pages/Terms/Terms";
import Thank from "./Components/pages/Thankyou/Thank";
import WhyUs from "./Components/pages/WhyUs/WhyUs";
import Services from "./Components/pages/Services/Services";
// import CarpetService from "./Components/pages/Services/CarpetService";
import BlogPageComp from "./Components/pages/Blog/BlogPageComp";
import Gallery from "./Components/pages/Gallery/Gallery";
import CarpetGallery from "./Components/pages/Gallery/CarpetGallery";
import RugGallery from "./Components/pages/Gallery/RugGallery";
import UpholsteryGallery from "./Components/pages/Gallery/UpholsteryGallery";
import LeatherGallery from "./Components/pages/Gallery/LeatherGallery";
import TilesGallery from "./Components/pages/Gallery/TilesGallery";
import UpholsteryService from "./Components/pages/Services/UpholsteryService";
import Specials from "./Components/pages/Specials/Specials";
import Location from "./Components/pages/Location/Location";
import ScrollToTop from "./Components/common/Scroll/ScrollToTop";
import Sitemap from "./Components/pages/Sitemap/Sitemap";
import NotFound from "./Components/pages/404/NotFound";
import NDIS from "./Components/pages/NDIS/NDIS";
import MattressGallery from "./Components/pages/Gallery/Mattress";
import Dandenong from "./Components/pages/Location/Dandenong";
import Pakenham from "./Components/pages/Location/Pakenham";
import Truganina from "./Components/pages/Location/Truganina";
import Lynbrook from "./Components/pages/Location/Lynbrook";
import Cranbourne from "./Components/pages/Location/Cranbourne";
import Narre from "./Components/pages/Location/Narre";
import DeepRug from "./Components/pages/LandingPages/DeepCleaning/DeepRug";
import UpholCarpet from "./Components/pages/LandingPages/UpholCarpet/UpholCarpet";
import FQuote from "./Components/pages/FQuote/FQuote";
import DryCarpet from "./Components/pages/LandingPages/DryCarpet/DryCarpet";
import CarpetLanding from "./Components/pages/LandingPages/Carpet/CarpetLanding";
import NewServiceCleaning from "./Components/pages/LandingPages/DeepCleaning/NewServiceCleaning";
import Test from "./Components/pages/Home/Test";
import FabricCouch from "./Components/pages/LandingPages/DeepCleaning/FabricCouch";
import LeatherFabric from "./Components/pages/LandingPages/DeepCleaning/LeatherCouch";
import CarpetNew from "./Components/pages/LandingPages/Carpet/CarpetNew";
import Feedback from "./Components/pages/Feedback/Feedback";
import PressureCleaning from "./Components/pages/LandingPages/PressureCleaning/PressureCleaning";

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/test" element={<Test />} />

          {/* Contact Us */}
          <Route exact path="/contact-us" element={<Contact />} />

          {/* Specials */}
          <Route exact path="/special-deals" element={<Specials />} />

          {/* Location */}
          <Route exact path="/locations" element={<Location />} />
          {/* Dandenong */}
          <Route
            exact
            path="/locations/carpet-cleaning-dandenong"
            element={<Dandenong />}
          />
          <Route
            exact
            path="/carpet-cleaning-dandenong"
            element={<Navigate to={"/locations/carpet-cleaning-dandenong"} />}
          />
          {/* Pakenham */}
          <Route
            exact
            path="/locations/carpet-cleaning-pakenham"
            element={<Pakenham />}
          />
          <Route
            exact
            path="/carpet-cleaning-pakenham"
            element={<Navigate to={"/locations/carpet-cleaning-pakenham"} />}
          />
          {/* Truganina */}
          <Route
            exact
            path="/locations/carpet-cleaning-truganina"
            element={<Truganina />}
          />
          <Route
            exact
            path="/carpet-cleaning-truganina"
            element={<Navigate to={"/locations/carpet-cleaning-truganina"} />}
          />
          {/* Lynbrook */}
          <Route
            exact
            path="/locations/carpet-cleaning-lynbrook"
            element={<Lynbrook />}
          />
          <Route
            exact
            path="/carpet-cleaning-lynbrook"
            element={<Navigate to={"/locations/carpet-cleaning-lynbrook"} />}
          />
          {/* Cranbourne */}
          <Route
            exact
            path="/locations/carpet-cleaning-cranbourne"
            element={<Cranbourne />}
          />
          <Route
            exact
            path="/carpet-cleaning-cranbourne"
            element={<Navigate to={"/locations/carpet-cleaning-cranbourne"} />}
          />
          {/* Narre Warren */}
          <Route
            exact
            path="/locations/carpet-cleaning-narre-warren"
            element={<Narre />}
          />
          <Route
            exact
            path="/carpet-cleaning-narre-warren"
            element={
              <Navigate to={"/locations/carpet-cleaning-narre-warren"} />
            }
          />

          {/* SiteMap */}
          <Route exact path="/sitemap" element={<Sitemap />} />

          {/* Gallery */}
          <Route exact path="/gallery" element={<Gallery />} />
          {/* carpet gallery */}
          <Route
            exact
            path="/gallery/carpet-cleaning-gallery"
            element={<CarpetGallery />}
          />
          <Route
            exact
            path="/carpet-cleaning-gallery-album"
            element={<Navigate to={"/gallery/carpet-cleaning-gallery"} />}
          />
          {/* rug gallery */}
          <Route
            exact
            path="/gallery/rug-cleaning-gallery"
            element={<RugGallery />}
          />
          <Route
            exact
            path="/rug-cleaning-gallery-album"
            element={<Navigate to={"/gallery/rug-cleaning-gallery"} />}
          />
          {/* upholstery gallery */}
          <Route
            exact
            path="/gallery/upholstery-cleaning-gallery"
            element={<UpholsteryGallery />}
          />
          <Route
            exact
            path="/upholstery-cleaning-gallery-album"
            element={<Navigate to={"/gallery/upholstery-cleaning-gallery"} />}
          />
          {/* leather gallery */}
          <Route
            exact
            path="/gallery/leather-cleaning-gallery"
            element={<LeatherGallery />}
          />
          {/* tile gallery */}
          <Route
            exact
            path="/gallery/tile-grout-cleaning-gallery"
            element={<TilesGallery />}
          />
          {/* Mattress gallery */}
          <Route
            exact
            path="/gallery/mattress-cleaning-gallery"
            element={<MattressGallery />}
          />
          <Route
            exact
            path="/mattress-cleaning-gallery-album"
            element={<Navigate to={"/gallery/mattress-cleaning-gallery"} />}
          />

          {/* Services */}
          <Route exact path="/services" element={<Services />} />
          {/* Carpet Service */}
          {/* <Route
            exact
            path="/services/carpet-cleaning-melbourne"
            element={<CarpetLanding />}
          /> */}
          <Route
            exact
            path="/services/carpet-cleaning-melbourne"
            element={<CarpetLanding />}
          />
          <Route exact path="/carpet-cleaning" element={<CarpetNew />} />

          {/* Carpet landing page */}
          <Route
            exact
            path="/carpet-cleaning-service"
            element={<NewServiceCleaning />}
          />

          {/* Fabric Couch */}
          <Route
            exact
            path="/fabric-couch-cleaning"
            element={<FabricCouch />}
          />
          {/* Leather COuch */}
          <Route
            exact
            path="/leather-couch-cleaning"
            element={<LeatherFabric />}
          />

          {/* Rug Service */}
          <Route
            exact
            path="/services/rug-cleaning-melbourne"
            element={<Navigate to={"/"} />}
          />
          {/* Upholstery Service */}
          <Route
            exact
            path="/services/upholstery-cleaning-melbourne"
            element={<UpholsteryService />}
          />
          <Route
            exact
            path="/upholstery-cleaning"
            element={
              <Navigate to={"/services/upholstery-cleaning-melbourne"} />
            }
          />
          {/* Rug Service */}
          {/* <Route
            exact
            path="/services/rug-repair-melbourne"
            element={<RugRepair />}
          /> */}
          {/* NDIS */}
          <Route
            exact
            path="/ndis-house-cleaning-australia"
            element={<NDIS />}
          />
          {/* <Route
            exact
            path="/ndis-house-cleaning-australia"
            element={
              <Navigate to={"/services/ndis-house-cleaning-australia"} />
            }
          /> */}

          {/* Thank You */}
          <Route exact path="/thank-you" element={<Thank />} />

          {/* Feedback */}
          <Route exact path="/feedback" element={<Feedback />} />

          {/* Why Us */}
          <Route exact path="/why-us" element={<WhyUs />} />

          {/* Blog  */}
          <Route exact path="/blog" element={<Blog />} />
          <Route
            exact
            path="/category/rug-cleaning"
            element={<Navigate to={"/blog"} />}
          />

          <Route
            exact
            path="/blog/sheep-skin-rug-cleaning"
            element={<BlogPageComp />}
          />
          <Route
            exact
            path="/blog/how-to-use-baking-soda-for-rug-cleaning"
            element={<BlogPageComp />}
          />
          <Route
            exact
            path="/blog/rug-cleaning-vs-carpet-cleaning"
            element={<BlogPageComp />}
          />
          <Route
            exact
            path="/blog/why-do-I-need-a-professional-rug-cleaner"
            element={<BlogPageComp />}
          />
          <Route
            exact
            path="/blog/tips-for-cleaning-cowhide-rug"
            element={<BlogPageComp />}
          />
          <Route
            exact
            path="/tips-for-cleaning-cowhide-rug"
            element={<Navigate to={"/blog/tips-for-cleaning-cowhide-rug"} />}
          />
          <Route
            exact
            path="/blog/how-to-wash-rugs-for-best-results"
            element={<BlogPageComp />}
          />
          <Route
            exact
            path="/blog/why-you-should-prefer-carpet-steam-cleaning-over-diy-cleaning-methods"
            element={<BlogPageComp />}
          />
          <Route
            exact
            path="/why-should-prefer-carpet-steam-cleaning-over-diy-cleaning-methods"
            element={
              <Navigate
                to={
                  "/blog/why-you-should-prefer-carpet-steam-cleaning-over-diy-cleaning-methods"
                }
              />
            }
          />
          <Route
            exact
            path="/blog/why-do-you-need-rug-cleaning-experts"
            element={<BlogPageComp />}
          />
          <Route
            exact
            path="/why-do-you-need-rug-cleaning-experts"
            element={
              <Navigate to={"/blog/why-do-you-need-rug-cleaning-experts"} />
            }
          />
          <Route
            exact
            path="/blog/what-is-best-for-you-rug-steam-dry-or-traditional-hand-cleaning"
            element={<BlogPageComp />}
          />
          <Route
            exact
            path="/best-rug-steam-dry-traditional-hand-cleaning"
            element={
              <Navigate
                to={
                  "/blog/what-is-best-for-you-rug-steam-dry-or-traditional-hand-cleaning"
                }
              />
            }
          />

          <Route
            exact
            path="/blog/how-to-clean-a-rug"
            element={<BlogPageComp />}
          />

          <Route exact path="/faq" element={<FAQ />} />

          {/* Terms and Conditions */}
          <Route exact path="/terms-and-conditions" element={<Terms />} />

          {/* Free Quote */}
          <Route exact path="/quote" element={<FQuote />} />

          {/* Landing Pages */}
          {/* Deep Cleaning */}
          <Route
            exact
            path="/rug-deep-cleaning"
            element={<DeepRug CR="RUG" cleaning="DEEP" />}
          />

          {/* Dry Cleaning */}
          <Route
            exact
            path="/carpet-dry-cleaning"
            element={<DeepRug CR="CARPET" cleaning="DRY" />}
          />
          <Route
            exact
            path="/rug-dry-cleaning"
            element={<DeepRug CR="RUG" cleaning="DRY" />}
          />

          {/* Steam Cleaning */}
          <Route
            exact
            path="/carpet-steam-cleaning"
            element={<DeepRug CR="CARPET" cleaning="STEAM" />}
          />
          <Route
            exact
            path="/rug-steam-cleaning"
            element={<DeepRug CR="RUG" cleaning="STEAM" />}
          />

          {/* Upholstery & Carpet Cleaning */}
          <Route
            exact
            path="/upholstery-carpet-cleaning"
            element={<UpholCarpet CR="RUG" cleaning="STEAM" />}
          />

          {/* Pressure Cleaning */}
          <Route
            exact
            path="/pressure-cleaning"
            element={<PressureCleaning />}
          />

          {/* Carpet Cleaning */}
          {/* <Route exact path="/carpet-cleaning" element={<CarpetLanding />} /> */}

          {/* DRY my wet Carpet */}
          <Route exact path="/dry-my-wet-carpet" element={<DryCarpet />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
